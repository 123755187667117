import React, { useState, useEffect } from "react";
import TopHeading from "components/Common/TopHeading";
import { CardBody, Card, Button } from "reactstrap";
import { useNavigate } from "react-router-dom";
import { createCollection } from "services/ProductsServices";
import CollectionForm from "components/Products/Collections/CollectionForm";
import ProgressPopup from "components/Common/Popups/ProgressPopup";
import PrimaryToast from "components/Common/Toast/ToastMessage";
import { toast } from "react-toastify";
import { setPageTitle } from "utils/pageTitle";

function CreateCollection() {
  const history = useNavigate();
  const [fields, setFields] = useState({
    is_hidden: true,
  });
  const [loading, setLoading] = useState(false);
  const [media, setMedia] = useState();
  const [description, setDescription] = useState();
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [faq, setFaq] = useState([
    {
      question: "",
      answer: "",
    },
  ]);

  useEffect(() => {
    setPageTitle("Create Product Collection - Inspire Uplift");
  }, []);

  const handleChange = e => {
    const name = e.target.name;
    const value =
      e.target.type === "checkbox" ? e.target.checked : e.target.value;
    const fieldsObj = { ...fields };
    fieldsObj[name] = value;
    setFields(fieldsObj);
  };

  const handleSelectChange = selected => {
    setSelectedOptions(selected);
  };

  const handleEditor = event => {
    const { name, value } = event;
    if (name === "description") {
      setDescription(value);
    }
  };
  const handleFaq = (e, index) => {
    let faqObj = [...faq];
    if (e.name == "answer") {
      faqObj[index][e.name] = e.value;
    } else {
      let name = e.target.name;
      let value = e.target.value;
      faqObj[index][name] = value;
    }
    setFaq(faqObj);
  };

  const handleAddQuestion = () => {
    const newFAQ = {
      question: "",
      answer: "",
    };
    setFaq([...faq, newFAQ]);
  };

  const handleDelete = itemIndex => {
    if (faq.length > 1) {
      const filterFAQ = faq.filter((item, index) => index !== itemIndex);
      setFaq(filterFAQ);
    }
  };

  const handleSubmit = e => {
    e.preventDefault();
    toast.dismiss();
    const filteredFaq = faq?.filter(
      item => item["question"] !== "" && item["answer"] !== ""
    );

    const data = {
      ...fields,
      slug:fields?.slug?.toLowerCase(),
      description: description || undefined,
      media: media || undefined,
      faq: filteredFaq || undefined,
    };
    setLoading(true);
    createCollection(data)
      .then(res => {
        history(-1);
        PrimaryToast({ type: "success", message: res?.data?.message });
      })
      .catch(err => {
        console.log(err);
        PrimaryToast({
          type: "error",
          message: Array.isArray(err?.response?.data?.message)
            ? err?.response?.data?.message[0]
            : err?.response?.data?.message,
        });
      })
      .finally(res => setLoading(false));
  };

  return (
    <div className="page-content">
      {loading && <ProgressPopup label="Loading..." />}

      <form onSubmit={handleSubmit}>
        <div className="d-flex justify-content-between align-items-center mb-3">
          <div>
            <TopHeading heading={"Create Product Collection"} />
          </div>
          <div className="d-flex align-items-center">
            <Button className="me-3" onClick={() => history(-1)}>
              Cancel
            </Button>
            <Button color="primary">Create</Button>
          </div>
        </div>
        <Card>
          <CardBody>
            <CollectionForm
              fields={fields}
              selectedOptions={selectedOptions}
              image={media}
              handleEditor={handleEditor}
              handleSelectChange={handleSelectChange}
              handleChange={handleChange}
              setMedia={setMedia}
              handleFaq={handleFaq}
              faq={faq}
              handleDelete={handleDelete}
              handleAddQuestion={handleAddQuestion}
            />
          </CardBody>
        </Card>
      </form>
    </div>
  );
}

export default CreateCollection;
