import React, { useState, useEffect } from "react";
import TopHeading from "components/Common/TopHeading";
import { Button, Card, CardBody } from "reactstrap";
import { useNavigate } from "react-router-dom";
import CollectionForm from "components/Products/Collections/CollectionForm";
import ProgressPopup from "components/Common/Popups/ProgressPopup";
import { updateCollection } from "services/ProductsServices";
import { getSingleCollection } from "services/ProductsServices";
import { useParams } from "react-router-dom";
import PrimaryToast from "components/Common/Toast/ToastMessage";
import { toast } from "react-toastify";
import { setPageTitle } from "utils/pageTitle";

function EditCollection() {
  const history = useNavigate();
  const { id } = useParams();
  const [fields, setFields] = useState({
    is_hidden: true,
  });
  const [loading, setLoading] = useState(false);
  const [description, setDescription] = useState();
  const [faq, setFaq] = useState([]);

  useEffect(() => {
    setPageTitle("Edit Product Collection - Inspire Uplift");
  }, []);

  useEffect(() => {
    setLoading(true);
    getSingleCollection(id)
      .then(res => {
        const staticDes = `Find something special at Inspire Uplift today. We’ve got great offers on ${res?.data?.title}, plus free shipping on all orders. Start shopping and save big!`;
        const seoDes = res?.data?.seo_description
          ? res?.data?.seo_description
          : staticDes;
        setFields({
          title: res?.data?.title || undefined,
          slug: res?.data?.slug || undefined,
          is_hidden: res?.data?.is_hidden == 0 ? true : false,
          seo_title: res.data?.seo_title || res?.data?.title || undefined,
          seo_description: seoDes || undefined,
          related_group_id: res?.data?.related_group_id,
          product_type: res?.data?.filters?.product_type || undefined,
          non_updatable:res?.data?.non_updatable == 1 ? true : false,
        });
        setDescription(res?.data?.description);
        let faqParse = res.data?.faq ? JSON.parse(res.data?.faq) : undefined;
        setFaq(faqParse);
      })
      .catch(err => console.log(err))
      .finally(res => setLoading(false));
  }, []);

  const handleChange = e => {
    const name = e.target.name;
    const value =
      e.target.type === "checkbox" ? e.target.checked : e.target.value;
    const fieldsObj = { ...fields };
    fieldsObj[name] = value;
    setFields(fieldsObj);
  };

  const handleEditor = event => {
    const { name, value } = event;
    if (name === "description") {
      setDescription(value);
    }
  };

  const handleSubmit = e => {
    e.preventDefault();
    toast.dismiss();
    const filteredFaq = faq?.filter(
      item => item["question"] !== "" && item["answer"] !== ""
    );
    const data = {
      ...fields,
      slug:fields?.slug?.toLowerCase(),
      description: description || "",
      faq: filteredFaq || undefined,
      non_updatable:fields?.non_updatable == true ? Number(1) : Number(0),
    };
    setLoading(true);
    updateCollection(data, id)
      .then(res => {
        history(-1);
        PrimaryToast({ type: "success", message: res?.data?.message });
      })
      .catch(err => {
        console.log(err);
        PrimaryToast({
          type: "error",
          message: Array.isArray(err?.response?.data?.message)
            ? err?.response?.data?.message[0]
            : err?.response?.data?.message,
        });
      })
      .finally(res => setLoading(false));
  };
  const handleAddQuestion = () => {
    setFaq(prevFaq => {
      const newFAQ = {
        question: "",
        answer: "",
      };
      return [...(prevFaq || []), newFAQ];
    });
  };

  const handleDelete = itemIndex => {
      const filterFAQ = faq.filter((item, index) => index !== itemIndex);
      setFaq(filterFAQ);
  };

  const handleFaq = (e, index) => {
    let faqObj = [...faq];
    if (e.name == "answer") {
      faqObj[index][e.name] = e.value;
    } else {
      let name = e.target.name;
      let value = e.target.value;
      faqObj[index][name] = value;
    }
    setFaq(faqObj);
  };

  return (
    <div className="page-content">
      {loading && <ProgressPopup label={"Loading..."} />}
      <form onSubmit={handleSubmit}>
        <div className="d-flex justify-content-between align-items-center mb-3">
          <div>
            <TopHeading heading={"Edit Product Collection"} />
          </div>
          <div className="d-flex align-items-center">
            <Button className="me-3" onClick={() => history(-1)}>
              Cancel
            </Button>
            <Button color="primary">Save</Button>
          </div>
        </div>
        <Card>
          <CardBody>
            <CollectionForm
              fields={fields}
              description={description}
              handleEditor={handleEditor}
              setFields={setFields}
              handleChange={handleChange}
              faq={faq}
              handleDelete={handleDelete}
              handleAddQuestion={handleAddQuestion}
              handleFaq={handleFaq}
              path={"edit"}
            />
          </CardBody>
        </Card>
      </form>
    </div>
  );
}

export default EditCollection;
