import React, { useState, useEffect, useMemo } from "react";
import TopHeading from "components/Common/TopHeading";
import { Button, Card, CardBody, Container } from "reactstrap";

import { useNavigate } from "react-router-dom";
import ImportCSVFilePopup from "components/Common/Popups/ImportCSVFilePopup";
import plusIcon from "../../assets/images/plus-icon.svg";
import PrimarySearch from "components/Common/PrimarySearch";
import Select from "react-select";
import {
  getAllOrders,
  getDownloadOrdersCsv,
  importCSV,
} from "services/OrderManagementService";
import PrimaryTable from "components/Common/PrimaryTable";
import ProgressPopup from "components/Common/Popups/ProgressPopup";
import PrimaryPagination from "components/Common/Pagination/PrimaryPagination";
import PrimaryToast from "components/Common/Toast/ToastMessage";
import DateRangePicker from "components/Common/DateRangePicker";
import moment from "moment";
import { useSelector } from "react-redux";
import { getAllProductsGroups } from "services/OrderManagementService";
import { setPageTitle } from "../../utils/pageTitle";
import useToggle from "hooks/useToggle";
import ResetButton from "components/Common/ResetButton";
import ExportButton from "components/Common/ExportButton";
import { convertLocalDatesToUTC, convertUtcDateToLocal } from "utils/date";

const OrderManagement = () => {
  const history = useNavigate();
  const [isImport, setIsImport] = useToggle(false);
  const [ordersData, setOrdersData] = useState([]);
  const [pageNo, setPageNo] = useState(1);
  const [pageCount, setPageCount] = useState();
  const [loading, setLoading] = useState(false);
  const [importFile, setImportFile] = useState();
  const params = new URLSearchParams(window.location.search);
  const paramsLength = Array.from(params.keys()).length;

  const [dates, setDates] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);

  const [fulfillmentStatus, setFulfillmentStatus] = useState("");
  const [platform, setPlatform] = useState("");
  const [searchVal, setSearchVal] = useState(null);
  const [showCalendar, setShowCalendar] = useToggle(false);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [options, setOptions] = useState([]);
  const user = useSelector(state => state.auth.user);
  const permissions = user?.permissions?.map(item => item.name);

  const getALLOptions = async () => {
    try {
      const res = await getAllProductsGroups();
      const customTypes = res?.data?.map(item => ({
        label: item.name,
        value: item.id,
      }));
      setOptions(customTypes);
      return customTypes; // Return the fetched options
    } catch (error) {
      console.log(error);
    }
  };

  const getOptions = useMemo(() => {
    return getALLOptions();
  }, []);

  useEffect(() => {
    setPageTitle("Order Management- Inspire Uplift");
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const fetchedOptions = await getOptions;
      const page = params.get("page") || undefined;
      const searchParam = params.get("str") || null;
      setSearchVal(searchParam || "");
      const fulfillment_status = params.get("fulfillment_status");
      setFulfillmentStatus(fulfillment_status || "");
      const productsGroupsParam = params.get("products_groups");

      const startDateParam = params.get("start_date");
      const endDateParam = params.get("end_date");
      const eventStartDate = moment(
        startDateParam || new Date(),
        "DD-MM-YYYY"
      ).toDate();
      const eventEndDate = moment(
        endDateParam || new Date(),
        "DD-MM-YYYY"
      ).toDate();
      setDates([
        {
          startDate: eventStartDate,
          endDate: eventEndDate,
          key: "selection",
        },
      ]);

      const { utcStartDate, utcEndDate } = convertLocalDatesToUTC(
        eventStartDate,
        eventEndDate
      );

      let productPrm = productsGroupsParam?.split(",");
      let selectedGroup;
      if (productPrm?.length > 0) {
        selectedGroup = fetchedOptions?.filter(item => {
          return productPrm?.some(item1 => item1 == item.label);
        });
        setSelectedOptions(selectedGroup);
      } else {
        setSelectedOptions([]);
      }

      const platform = params.get("platform");
      setPlatform(platform || "");

      let groupSelect = selectedGroup?.map(item => item.value);

      let applyFilter = {
        fulfillment_status: fulfillment_status || undefined,
        start_date: utcStartDate,
        end_date: utcEndDate,
        platform: platform || undefined,
        product_groups: groupSelect || undefined,
      };

      setPageNo(page || 1);

      if (permissions?.includes("order-filters")) {
        getAllOrdersData(page, searchParam, applyFilter);
      } else {
        getAllOrdersData(page, searchParam);
      }
    };
    fetchData();
  }, [window?.location?.search]);

  const getAllOrdersData = (page, search, status) => {
    setLoading(true);
    getAllOrders(page, search, status)
      .then(res => {
        setOrdersData(res.data.data);
        setPageCount(res?.data?.last_page);
      })
      .catch(err => {
        console.log(err);
      })
      .finally(err => {
        setLoading(false);
      });
  };

  const columns = [
    {
      dataField: "id",
      text: "Order ID",
      formatter: (cellContent, row) => (
        <span className={`font-semi-bold text-gray-700 text-13`}>{row.id}</span>
      ),
    },
    {
      dataField: "shipping_name",
      text: "Shipping Name",
    },
    {
      dataField: "email",
      text: "Email",
    },
    {
      dataField: "fulfillment_status",
      text: "Fulfillment Status",
      formatter: (cellContent, row) => {
        return (
          <span className="fulfilment_status">
            {row.fulfillment_status == 0
              ? "Unfulfilled"
              : row.fulfillment_status == 1
              ? "Fulfilled"
              : row.fulfillment_status == 2
              ? "Partial Fulfilled"
              : row.fulfillment_status == 3
              ? "Canceled"
              : row.fulfillment_status == 4
              ? "Ordered"
              : ""}
          </span>
        );
      },
    },
    {
      dataField: "financial_status",
      text: "Status",
      formatter: (cellContent, row) => {
        return (
          <span
            className={`${
              row.financial_status === "Paid"
                ? "success-status"
                : row.financial_status === "Refunded" ||
                  row.financial_status === "Partially Refunded"
                ? "danger-status"
                : row.financial_status === "Unpaid"
                ? "warning-status"
                : ""
            } status`}
          >
            {row.financial_status}
          </span>
        );
      },
    },
    {
      dataField: "platform",
      text: "Platform",
    },
    {
      dataField: "created_at",
      text: "Order Date",
      formatter: (cell, row) => <div>{convertUtcDateToLocal(row.created_at, "DD-MM-YYYY")}</div>
    },
    {
      dataField: "refund_total",
      text: "Refund Total",
      formatter: (cell, row) => <div>${row?.refund_total?.toFixed(2)}</div>,
    },
    {
      dataField: "total_price",
      text: "Grand Total",
      formatter: (cell, row) => <div>${row?.total_price?.toFixed(2)}</div>,
    },
  ];

  const handleDatePicker = item => {
    setDates([item.selection]);
  };

  const data = ordersData?.map(item => {
    return {
      ...item,
    };
  });

  const handleFileUpload = event => {
    const file = event.target.files[0];
    if (file && file.type === "text/csv") {
      setImportFile(file);
    } else {
      alert("Please select a CSV file.");
    }
  };

  const handleImportTracking = () => {
    setIsImport(!isImport);
  };

  const handleSelectChange = selected => {
    setSelectedOptions(selected);
  };

  const handleSearch = e => {
    setSearchVal(e.target.value);
  };

  const downloadCSV = async () => {
    const fulfillment_status = params.get("fulfillment_status");
    const productsGroupsParam = params.get("products_groups");
    const decodedProductsGroupsParam = decodeURIComponent(productsGroupsParam);
    const sanitizedProductsGroupsParam = decodedProductsGroupsParam
      .replace(/%22/g, '"')
      .replace(/%20/g, " ");

    let productsGroups;

    try {
      productsGroups = JSON.parse(sanitizedProductsGroupsParam);
    } catch (error) {
      console.error("Error parsing products_groups parameter:", error);
    }
    const startDate = params.get("start_date");
    const endDate = params.get("end_date");
    const platform = params.get("platform");
    let applyFilter = {
      fulfillment_status: fulfillment_status || undefined,
      start_date: startDate,
      end_date: endDate,
      platform: platform || undefined,
      product_groups: productsGroups || undefined,
    };

    const response = await getDownloadOrdersCsv(applyFilter);
    return response;
  };

  const handleSave = () => {
    setLoading(true);
    importCSV(importFile)
      .then(res => {
        PrimaryToast({ type: "success", message: "File Import successfully" });
        setIsImport(!isImport);
      })
      .catch(err => {
        PrimaryToast({
          type: "error",
          message: Array?.isArray(err?.response?.data?.message)
            ? err?.response?.data?.message[0]
            : err?.response?.data?.message,
        });
      })
      .finally(res => {
        setLoading(false);
      });
  };

  const customStyles = {
    control: provided => ({
      ...provided,
      borderRadius: "999px",
    }),
  };

  const handleKeyPress = e => {
    if (e.key === "Enter") {
      handleApplyFilter();
    }
  };

  const handleApplyFilter = () => {
    if (searchVal) {
      params.set("str", searchVal);
    } else {
      params.delete("str");
    }

    if (fulfillmentStatus) {
      params.set("fulfillment_status", fulfillmentStatus);
    } else {
      params.delete("fulfillment_status");
    }
    if (permissions?.includes("order-filters")) {
      params.set("start_date", moment(dates[0].startDate).format("DD-MM-YYYY"));
      params.set("end_date", moment(dates[0].endDate).format("DD-MM-YYYY"));
    }
    if (selectedOptions?.length > 0) {
      let value = selectedOptions.map(item => item.label);
      params.set("products_groups", value);
    } else {
      params.delete("products_groups");
    }
    if (platform) {
      params.set("platform", platform);
    } else {
      params.delete("platform");
    }
    if (params?.get("page")) {
      params.delete("page");
    }
    let newUrl = `${location.pathname}?${params.toString()}`;
    history(newUrl, { replace: true });
  };

  return (
    <React.Fragment>
      {loading && <ProgressPopup label={"Loading..."} />}
      <div
        className="page-content order-managment-container"
        style={{ minHeigt: "800px" }}
      >
        <Container fluid>
          <div className="mb-4 d-flex justify-content-between align-items-center">
            <TopHeading heading={"Order Management"} />
            <div className="text-sm-end d-flex align-items-center justify-content-end">
              {permissions?.includes("tracking-import") ? (
                <Button
                  type="button"
                  color="primary"
                  className="mb-2 me-3"
                  onClick={handleImportTracking}
                >
                  <img src={plusIcon} alt="import icon" className="pe-2" />
                  import Tracking
                </Button>
              ) : null}
              {permissions?.includes("export-order") &&
                paramsLength > 0 &&
                ordersData?.length > 0 && (
                  <div className="mb-2 me-2">
                    <ExportButton
                      isLoading={loading}
                      setIsLoading={setLoading}
                      fetchData={downloadCSV}
                    />
                  </div>
                )}
            </div>
          </div>
          <Card>
            <CardBody>
              <div className="d-flex align-items-start mb-3">
                <div className="mt-3">
                  <PrimarySearch
                    handleKeyPress={handleKeyPress}
                    handleChange={handleSearch}
                    placeholder={"Search by name, email,order number "}
                    value={searchVal}
                  />
                </div>

                {permissions?.includes("order-filters") && (
                  <div className="d-flex flex-column">
                    <div className="d-flex flex-wrap">
                      <select
                        className="primary-select-option ms-1 mt-3"
                        name="fulfillment_status"
                        value={fulfillmentStatus || ""}
                        onChange={e => {
                          setFulfillmentStatus(e.target.value);
                        }}
                        onKeyPress={handleKeyPress}
                      >
                        <option value={""}>Select Fulfillment Status</option>
                        <option value={"unfulfilled"}>Unfulfilled</option>
                        <option value={"fulfilled"}>Fulfilled</option>
                        <option value={"ordered"}>Ordered</option>
                        <option value={"partial_fulfilled"}>
                          Partial Fulfilled
                        </option>
                      </select>
                      <div className="position-relative ms-1 mt-3">
                        <div
                          className="form-control cursor-pointer rounded-full"
                          style={{ color: "#74788D", width: "max-content" }}
                          onClick={() => setShowCalendar(true)}
                        >
                          {moment(dates[0].startDate).format("DD MMM YYYY")} -{" "}
                          {moment(dates[0].endDate).format("DD MMM YYYY")}
                        </div>

                        {showCalendar && (
                          <>
                            <div
                              className="position-fixed top-0 start-0 w-100 h-100"
                              style={{ zIndex: "10" }}
                              onClick={() => setShowCalendar(false)}
                            ></div>
                            <DateRangePicker
                              dates={dates}
                              handleDatePicker={handleDatePicker}
                              handleSubmit={() => setShowCalendar(false)}
                              // minDate={new Date()}
                              maxDate={new Date()}
                            />
                          </>
                        )}
                      </div>
                      <div
                        className="ms-1 rounded-full mt-3"
                        style={{ minWidth: "250px" }}
                      >
                        <Select
                          isMulti={true}
                          options={options}
                          onChange={handleSelectChange}
                          value={selectedOptions}
                          name={"seller_groups_ids"}
                          placeholder={"Select Product Group"}
                          styles={customStyles}
                        />
                      </div>
                      <select
                        className="primary-select-option ms-1 mt-3"
                        name="platform"
                        value={platform || ""}
                        onKeyPress={handleKeyPress}
                        onChange={e => {
                          setPlatform(e.target.value);
                        }}
                      >
                        <option value={""}>Select Platform</option>
                        <option value="web"> WEB </option>
                        <option value="ios"> IOS </option>
                        <option value="android"> Android </option>
                      </select>
                      <Button
                        color="primary"
                        className={`btn-rounded  mt-3 ms-1`}
                        onClick={handleApplyFilter}
                      >
                        Apply Filter
                      </Button>
                      <div className="d-flex align-items-center mt-3">
                        {paramsLength > 0 && <ResetButton />}
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <div className="table-responsive">
                <PrimaryTable
                  columns={columns}
                  data={data}
                  keyField={"id"}
                  bordered={false}
                  isPermission
                  rowUrl={"/orders/details"}
                />
              </div>
            </CardBody>
            {ordersData?.length > 0 && pageCount > 1 && (
              <div className="">
                <PrimaryPagination
                  pageNo={pageNo}
                  setPageNo={setPageNo}
                  pageCount={pageCount}
                />
              </div>
            )}
          </Card>
          {isImport && (
            <ImportCSVFilePopup
              handleSave={handleSave}
              isOpen={isImport}
              handleClick={handleImportTracking}
              handleFileUpload={handleFileUpload}
              title={"Import Tracking"}
            />
          )}
        </Container>
      </div>
    </React.Fragment>
  );
};
export default OrderManagement;
