import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { toast } from "react-toastify";
import {
  CardBody,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
  Card,
  UncontrolledTooltip,
} from "reactstrap";

import TopHeading from "components/Common/TopHeading";
import PrimarySearch from "components/Common/PrimarySearch";
import editIcon from "../../assets/images/edit-icon-white.svg";
import optionIcon from "../../assets/images/options-icon.svg";
import {
  getAllCustomers,
  deleteCustomer,
  customerAction,
  customerLogin,
  subscriptionCancle,
} from "services/CustomerServices";
import PrimaryTable from "components/Common/PrimaryTable";
import PrimaryPagination from "components/Common/Pagination/PrimaryPagination";
import ProgressPopup from "components/Common/Popups/ProgressPopup";
import PrimaryToast from "components/Common/Toast/ToastMessage";
import DeleteModal from "components/Common/Toast/DeleteModal";
import loginIcon from "assets/images/login-icon.svg";
import { setPageTitle } from "utils/pageTitle";
import { openLinkInNewTab } from "utils/linkHandling";
import useToggle from "hooks/useToggle";
import VipImage from "assets/images/VIP.png";
import { userAgentLog } from "utils/userAgentLog";
import DateRangePicker from "components/Common/DateRangePicker";
import { convertLocalDatesToUTC } from "utils/date";

function ContactsList() {
  const [customersData, setCustomersData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pageNo, setPageNo] = useState(1);
  const [pageCount, setPageCount] = useState();
  const [search, setSearch] = useState("");
  const [selectedId, setSelectedId] = useState();
  const [showDeleteModal, setShowDeleteModal] = useToggle(false);
  const [searchChanged, setSearchChanged] = useState(false);
  const [isVip, setIsVip] = useState();
  const [showCalendar, setShowCalendar] = useToggle(false);
  const queryParams = new URLSearchParams(window.location.search);

  useEffect(() => {
    setPageTitle("Customers - Inspire Uplift");
  }, []);

  const [dates, setDates] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);

  useEffect(() => {
    const params = new URLSearchParams(window.location?.search);
    const page = params?.get("page") || undefined;
    const searchParam = params?.get("search") || undefined;
    const startDateParam = params?.get("start") || undefined;
    const endDateParam = params?.get("end") || undefined;
    setSearch(searchParam || "");
    setPageNo(page || 1);

    const eventStartDate = moment(
      startDateParam || new Date(),
      "DD-MM-YYYY"
    ).toDate();
    const eventEndDate = moment(
      endDateParam || new Date(),
      "DD-MM-YYYY"
    ).toDate();
    if (
      searchParam ||
      page ||
      searchChanged ||
      startDateParam ||
      endDateParam
    ) {
      if ((startDateParam, endDateParam)) {
        setDates([
          {
            startDate: eventStartDate,
            endDate: eventEndDate,
            key: "selection",
          },
        ]);
      }
      let startDate = startDateParam ? convertLocalDatesToUTC(
        eventStartDate,
        eventEndDate
      )?.utcStartDate :  undefined;
      const endDate = endDateParam ? convertLocalDatesToUTC(
        eventStartDate,
        eventEndDate
      ) : null;
  
      getCustomersData(page, searchParam, startDate, endDate);
    }
  }, [window?.location?.search, searchChanged]);

  const getCustomersData = (page, search, start, end) => {
    setLoading(true);
    getAllCustomers(page, search, start, end)
      .then(res => {
        setCustomersData(res?.data?.customers?.data);
        setPageCount(res?.data?.customers?.last_page);
      })
      .catch(err => console.log(err))
      .finally(res => setLoading(false));
  };

  const history = useNavigate();

  let randomColor = () => {
    return Math.random().toString(16).substr(-6);
  };

  const handleDelete = () => {
    const filterArr = customersData?.filter(item => item.id !== selectedId);
    toast.dismiss();
    setLoading(true);
    deleteCustomer(selectedId)
      .then(res => {
        if (res.status === 200) {
          PrimaryToast({ type: "success", message: res?.data?.message });
          if (isVip == 1) {
            subscriptionCancle(selectedId)
              .then(res => {
                console.log(res, "check response");
              })
              .catch(err => {
                PrimaryToast({
                  type: "error",
                  message: err?.response?.data?.message,
                });
              });
          }
          setCustomersData(filterArr);
          setShowDeleteModal(false);
          setSelectedId();
          setIsVip();
        }
      })
      .catch(err => {
        console.log(err);
        PrimaryToast({ type: "error", message: err?.response?.data?.message });
      })
      .finally(res => setLoading(false));
  };

  const handleDatePicker = item => {
    setDates([item.selection]);
  };

  const handleAction = (e, id, type, isVip) => {
    e.stopPropagation();
    toast.dismiss();
    setLoading(true);
    customerAction(id, type)
      .then(res => {
        if (res.status === 200) {
          PrimaryToast({ type: "success", message: res?.data?.message });
          if (type == 0 && isVip == 1) {
            subscriptionCancle(id)
              .then(res => {
                console.log(res, "check response");
              })
              .catch(err => {
                PrimaryToast({
                  type: "error",
                  message: err?.response?.data?.message,
                });
              })
              .finally(res => {
                getCustomersData(pageNo, search);
              });
          } else {
            getCustomersData(pageNo, search);
          }
        }
      })
      .catch(err => {
        console.log(err);
        PrimaryToast({
          type: "error",
          message: err?.response?.data?.message[0],
        });
      })
      .finally(res => setLoading(false));
  };

  const columns = [
    {
      dataField: "id",
      text: "#",
      formatter: (cell, row) => (
        <div className="font-semi-bold">{(pageNo - 1) * 10 + row.index}</div>
      ),
    },
    {
      dataField: "name",
      text: "Name",
      formatter: (cell, row) => (
        <div className="d-flex align-items-center">
          <div className="avatar-xs me-2">
            <span
              className="avatar-title rounded-circle"
              style={{
                background: `#${randomColor()}`,
                textTransform: "capitalize",
              }}
            >
              {row.first_name.charAt(0)}
            </span>
          </div>
          {row.first_name} {row.last_name}
          {row?.subscription == 1 && (
            <img
              src={VipImage}
              alt="vip customer"
              className="ms-2"
              height={22}
              width={34}
            />
          )}
        </div>
      ),
    },
    {
      dataField: "email",
      text: "Email",
    },
    {
      dataField: "orders_count",
      text: "Orders",
    },
    {
      dataField: "is_active",
      text: "Status",
      formatter: (cell, row) => {
        let status;
        switch (row.is_active) {
          case 0:
            status = "Inactive";
            break;
          case 1:
            status = "Active";
            break;
          case 2:
            status = "Block";
            break;
          default:
            status = "Inactive";
        }
        return (
          <div
            className={`status ${
              status === "Active"
                ? "success-status"
                : status === "Inactive" || status == "Block"
                ? "danger-status"
                : ""
            }  w-max-content`}
          >
            {status}
          </div>
        );
      },
    },
    {
      dataField: "action",
      text: "Action",
      formatter: (cellContent, row) => (
        <div className="d-flex justify-content-center align-items-center">
          <div
            className="action-btn edit-icon-bg me-1 cursor-pointer"
            onClick={e => {
              e.stopPropagation();
              history(
                `/customers/edit/${row.id}${
                  row?.subscription == 1 ? `?vipCustomer=1` : ""
                }`
              );
            }}
          >
            <img src={editIcon} className="" alt="edit icon" />
          </div>
          {row.is_active === 1 && (
            <div>
              <div
                className="action-btn edit-icon-bg  cursor-pointer"
                onClick={e => handleLogin(e, row.id)}
                id={`tooltip-${row.id}`}
              >
                <img src={loginIcon} className="" alt="delete icon" />
              </div>
              <UncontrolledTooltip
                target={`tooltip-${row.id}`}
                placement="bottom"
              >
                Login
              </UncontrolledTooltip>
            </div>
          )}
          <div className="">
            <UncontrolledDropdown>
              <DropdownToggle
                tag="div"
                className="card-drop cursor-pointer px-3"
                data-bs-toggle="dropdown"
                aria-expanded="false"
                onClick={e => {
                  e.stopPropagation();
                }}
              >
                <img src={optionIcon} alt="rectangeArrow" />
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-end">
                {row.is_active === 1 && (
                  <DropdownItem
                    onClick={e => handleAction(e, row.id, 0, row?.subscription)}
                  >
                    Disable
                  </DropdownItem>
                )}
                {(row.is_active === 0 || row.is_active === 2) && (
                  <DropdownItem
                    onClick={e => handleAction(e, row.id, 1, row?.subscription)}
                  >
                    Enable
                  </DropdownItem>
                )}
                <DropdownItem
                  onClick={e => {
                    e.stopPropagation();
                    setSelectedId(row.id);
                    setIsVip(row.subscription);
                    setShowDeleteModal(true);
                  }}
                >
                  Delete
                </DropdownItem>
                {row?.is_active != 2 && (
                  <DropdownItem
                    onClick={e => handleAction(e, row.id, 2, row?.subscription)}
                  >
                    Block
                  </DropdownItem>
                )}
              </DropdownMenu>
            </UncontrolledDropdown>
          </div>
        </div>
      ),
    },
  ];

  const data = customersData?.map((item, index) => {
    return {
      ...item,
      index: index + 1,
    };
  });

  const handleSearch = e => {
    const value = e.target.value;
    setSearch(value);
  };

  const handleKeyPress = e => {
    if (e.key === "Enter") {
      setSearchChanged(!searchChanged);
      if (search) {
        queryParams.set("search", search);
      }
      if (!search) {
        queryParams.delete("search");
      }
      if (queryParams.get("page") || queryParams.get("type")) {
        queryParams.delete("page");
        queryParams.delete("type");
      }
      if (queryParams.get("start") || queryParams.get("end")) {
        queryParams.delete("start");
        queryParams.delete("end");
      }
      history(`?${queryParams.toString()}`);
    }
  };

  const handleLogin = async (e, id) => {
    e.stopPropagation();
    setLoading(true);

    try {
      const userData = await userAgentLog(); // Get user data using userAgentLog
      const userDataJsonString = JSON.stringify(userData); // Convert user data to JSON string
      const res = await customerLogin(id, userDataJsonString); // Pass the stringified user data
      if (res.data.token) {
        if (window.location.hostname.includes("ods.inspireuplift.com")) {
          openLinkInNewTab("https://www.inspireuplift.com/");
        } else {
          openLinkInNewTab("https://staging.itdeptiu.com/");
        }
      }
    } catch (err) {
      PrimaryToast({ type: "error", message: err?.response?.data?.message });
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const handleDates = () => {
    setShowCalendar(false);
    queryParams.set("start", moment(dates[0].startDate).format("DD-MM-YYYY"));
    queryParams.set("end", moment(dates[0].endDate).format("DD-MM-YYYY"));
    queryParams.delete("search");
    queryParams.delete("page");
    queryParams.delete("type");
    history(`?${queryParams.toString()}`);
  };

  return (
    <div className="page-content customers-container">
      {showDeleteModal && (
        <DeleteModal
          title={"Are you sure to delete this customer?"}
          onConfirm={handleDelete}
          onCancel={() => {
            setSelectedId("");
            setShowDeleteModal(!showDeleteModal);
            setIsVip();
          }}
        />
      )}
      <div className="mb-3 d-flex justify-content-between align-items-center">
        <TopHeading heading={"Customers"} />
      </div>
      <Card>
        <CardBody>
          {loading && <ProgressPopup label={"Loading..."} />}
          <div className="d-flex justify-content-between align-items-center">
            <PrimarySearch
              placeholder={"Search By name, email"}
              value={search}
              handleChange={handleSearch}
              handleKeyPress={handleKeyPress}
            />
            <div className="position-relative ms-1 mt-3 ">
              <div
                className="form-control cursor-pointer rounded-full"
                style={{ color: "#74788D", width: "max-content" }}
                onClick={() => setShowCalendar(true)}
              >
                {moment(dates[0].startDate).format("DD MMM YYYY")} -{" "}
                {moment(dates[0].endDate).format("DD MMM YYYY")}
              </div>

              {showCalendar && (
                <>
                  <div
                    className="position-fixed top-0 start-0 w-100 h-100"
                    style={{ zIndex: "10" }}
                    onClick={() => setShowCalendar(false)}
                  ></div>
                  <DateRangePicker
                    dates={dates}
                    handleDatePicker={handleDatePicker}
                    handleSubmit={handleDates}
                    // minDate={new Date()}
                    maxDate={new Date()}
                  />
                </>
              )}
            </div>
          </div>
          <div className="mt-3">
            <PrimaryTable
              columns={columns}
              data={data}
              bordered={false}
              keyField="id"
              isPermission={true}
              rowUrl={"/customers/detail"}
            />
          </div>
          {customersData?.length > 0 && pageCount > 1 && (
            <PrimaryPagination
              pageCount={pageCount}
              setPageNo={setPageNo}
              pageNo={pageNo}
            />
          )}
        </CardBody>
      </Card>
    </div>
  );
}

export default ContactsList;
