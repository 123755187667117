import React, { useState, useEffect } from "react";
import TopHeading from "components/Common/TopHeading";
import PrimarySearch from "components/Common/PrimarySearch";
import { CardBody, Card } from "reactstrap";
import tickIcon from "../../assets/images/tick-icon.svg";
import cancleIcon from "../../assets/images/cancle-icon.svg";
import {
  getProductApprovals,
  productAction,
  productApprovedBySeller,
} from "services/ProductsServices";
import ProgressPopup from "components/Common/Popups/ProgressPopup";
import PrimaryTable from "components/Common/PrimaryTable";
import PrimaryPagination from "components/Common/Pagination/PrimaryPagination";
import PrimaryToast from "components/Common/Toast/ToastMessage";
import ProductRejectPopup from "components/Common/Popups/ProductRejectPopup";
import ProductApprovedInput from "components/Products/ProductApprovedInput";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { setPageTitle } from "utils/pageTitle";
import cancleGrayIcon from "assets/images/cross-gray-icon.svg";
import { useNavigate } from "react-router-dom";
import useToggle from "hooks/useToggle";
import { convertUtcDateToLocal } from "utils/date";

let requestTimer;
function ProductsApproval() {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [productApprovalData, setProductApprovalData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pageNo, setPageNo] = useState(1);
  const [pageCount, setPageCount] = useState();
  const [selectedTitle, setSelectedTitle] = useState("");
  const [showDeleteModal, setShowDeleteModal] = useToggle(false);
  const [reason, setReason] = useState("");
  const [selectedId, setSelectedId] = useState("");
  const [seller, setSeller] = useState("");
  const [searchVal, setSearch] = useState(null);
  const [sellerIdFil, setSellerIdFil] = useState();
  const [actionType, setActionType] = useState('');
  const history = useNavigate();
  const user = useSelector(state => state.auth.user);
  const permissions = user?.permissions?.map(item => item.name);
  const params = new URLSearchParams(window?.location?.search);
  useEffect(() => {
    setPageTitle("Products Approval - Inspire Uplift");
  }, []);

  useEffect(() => {

    const page = params?.get("page") || 1;
    const search = params?.get("search") || undefined;
    setPageNo(page);
    setSearch(search || "");
    setLoading(true);
    getProducts(page, search);
  }, [window?.location?.search]);

  const getProducts = (page, search) => {
    getProductApprovals(page, search)
      .then(res => {
        setProductApprovalData(res.data.data);
        setPageCount(res.data.last_page);
      })
      .catch(err => console.log(err))
      .finally(res => setLoading(false));
  };

  const columns = [
    {
      dataField: "id",
      text: "#",
      formatter: (cell, row) => (
        <div className="font-semi-bold">{(pageNo - 1) * 10 + row.index}</div>
      ),
    },
    {
      dataField: "product_name",
      text: "Product Name",
      formatter: (cell, row) => (
        <div
          className="primary-text cursor-pointer"
          style={{ width: "230px", whiteSpace: "break-spaces" }}
          onClick={() => window?.open(`${row?.seller_product?.url}`, "_blank")}
        >
          {row.seller_product?.name}
        </div>
      ),
    },
    {
      dataField: "keywords",
      text: "Keyword Detect",
      formatter: (cell, row) => {
        return (
          <div className="d-flex flex-wrap" style={{width:'300px'}}>
            {row?.keywords &&
              Object.values(row?.keywords).map((item, index) => {
                return (
                  <div key={index} className={`ms-1 ${item?.toLowerCase() == "duplicated" ? "border border-danger text-danger p-1 rounded" : ""}`}>
                    {item}
                    {Object.values(row?.keywords).length - 1 === index
                      ? ""
                      : ","}
                  </div>
                );
              })}
          </div>
        );
      },
    },

    {
      dataField: "store_name",
      text: "Store Name",
      formatter: (cell, row) => <div>{row.seller_product?.store_name}</div>,
    },
    {
      dataField: "created_at",
      text: "Date",
      formatter: (cell, row) => <div>{row.created_at ? convertUtcDateToLocal(row.created_at, "DD-MM-YYYY") : null}</div>,
    },
    {
      dataField: "action",
      text: "Action",
      formatter: (cell, row) =>
        permissions?.includes("seller_products") ? (
          <div className="d-flex align-items-center">
            <div
              className="action-btn delete-btn-bg me-1 cursor-pointer"
              onClick={() => {
                setSelectedTitle(row.seller_product?.name);
                setSelectedId(row?.seller_product_id);
                setShowDeleteModal(true);
              }}
            >
              <img src={cancleIcon} className="" alt="delete icon" />
            </div>
            <button
              className="action-btn approve-btn-bg cursor-pointer border-none outline-none"
              id={`tick-icon${row.seller_product_id}-container`}
              onClick={() => handleProductAction([row.seller_product_id], 2)}
            >
              <img src={tickIcon} className="" alt="edit icon" />
            </button>
          </div>
        ) : null,
    },
  ];

  const data = productApprovalData?.map((item, index) => {
    return {
      ...item,
      index: index + 1,
    };
  });

  const handleSave = () => {
	let ids = selectedRowKeys?.length > 0 ? selectedRowKeys : [selectedId]
    handleProductAction(ids, 3, reason);
  };

  const handleProductAction = (product_ids, status, reason) => {
    document.querySelectorAll(".approve-btn-bg").forEach(item => {
      item.disabled = true;
    });
    toast.dismiss();
	const filterData = productApprovalData?.filter((product) => {
		return !product_ids?.includes(product.seller_product_id);
	});
    setLoading(true);
	
    clearTimeout(requestTimer);
    requestTimer = setTimeout(() => {
      productAction(product_ids, status, reason)
        .then(res => {
          PrimaryToast({ type: "success", message: res?.data?.message });
		  if(filterData?.length == 0 && pageNo > 1){
			handleClose();
			params.set("page", pageNo -1);
			const newUrl = `${window.location.pathname}?${params.toString()}`;
			history(newUrl, { replace: true });
		  }
		  else {
			getProducts(pageNo, searchVal);
			handleClose();
		  }     
        })
        .catch(err => {
          PrimaryToast({
            type: "error",
            message: Array.isArray(err?.response?.data?.message) ? err?.response?.data?.message[0] : err?.response?.data?.message,
          });

          console.log(err);
          setLoading(false);
        })
        .finally(res => {
          document.querySelectorAll(".approve-btn-bg").forEach(item => {
            item.disabled = false;
          });
        });
    }, 500);
  };

  const handleClose = () => {
    setSelectedTitle("");
    setSelectedId("");
	setSelectedRowKeys([]);
	setActionType('');
    setShowDeleteModal(false);
	setReason('');
  };

  const handleSellerProduct = (status) => {
    toast.dismiss();
    setLoading(true);
    setSellerIdFil(seller);
    productApprovedBySeller(seller,status,reason)
      .then(res => {
        PrimaryToast({
          type: "success",
          message: res?.data?.message,
        });
		if(status == 2) {
			handleClose()
		};
		if(pageNo > 1) {
			params.set("page",1)
			const newUrl = `${window.location.pathname}?${params?.toString()}`;
			history(newUrl, { replace: true });
		}
		else {
			getProducts(pageNo, searchVal);
		}

      })
      .catch(err => {
        console.log(err);
        PrimaryToast({ 
          type: "error",
          message: Array.isArray(err?.response?.data?.message)
            ? err?.response?.data?.message[0]
            : err?.response?.data?.message,
        });
      })
      .finally(res => {
        setLoading(false);
      });
  };

  const handleKeyPress = e => {
    if (e.key === "Enter") {
      if (searchVal) {
        params.set("search", searchVal);
      }
	  else {
		params.delete("search");
	  }
      if (params.get("page")) {
        	params.delete("page");
      }
	  history(`?${params.toString()}`);
    }
  };

  const handleClear = type => {
    if (type === "search") {
      setSearch("");
	  params?.delete('search');
	  history(`?${params.toString()}`);
    } else if (type === "sellerId") {
      setSellerIdFil("");
      setSeller("");
    }
  };

  const handleOnSelectAll = (isSelected, rows,e) => {
    if (isSelected) {
      setSelectedRowKeys(rows.map(row => row.seller_product_id));
    } else {
      setSelectedRowKeys([]);
    }
  };

  const selectRow =  permissions?.includes("seller_products") ? {
    mode: "checkbox",
    onSelect: (row, isSelect, rowIndex, e) => {
      if (isSelect) {
        setSelectedRowKeys([...selectedRowKeys, row.seller_product_id]);
      } else {
        setSelectedRowKeys(selectedRowKeys.filter(key => key !== row.seller_product_id));
      }
    },
	onSelectAll: handleOnSelectAll,
    selected: selectedRowKeys,
  } : undefined;

  return (
    <div className="page-content">
      {loading && <ProgressPopup label={"Loading..."} />}
      <div className="mb-3">
        <TopHeading heading={"Seller Products Approval"} />
      </div>
      <Card>
        <CardBody>
          <div className="d-flex justify-content-between align-items-start">
            <div className="flex flex-column">
              <PrimarySearch
                placeholder={"search by product name, store name"}
                value={searchVal}
				handleChange={(e) => setSearch(e.target?.value)}
                handleKeyPress={handleKeyPress}
              />
              {params?.get("search") && (
                <div className="mt-3 flex position-relative bg-gray-light text-gray-700 w-max-content p-1  d-flex justify-content-between align-items-start rounded">
                  {params?.get("search")}
                  <img
                    src={cancleGrayIcon}
                    onClick={() => handleClear("search")}
                    height="10"
                    className="mt-1 ms-1 cursor-pointer"
                    width="10"
                    alt="cancel icon"
                  />
                </div>
              )}
            </div>
            {permissions?.includes("seller-name-approve") && (
              <div>
                <ProductApprovedInput
                  handleClick={handleSellerProduct}
                  seller={seller}
                  setSeller={setSeller}
				  loading={loading}
				  setActionType={setActionType}
				  setShowDeleteModal={setShowDeleteModal}
				  selectedRowKeys={selectedRowKeys}
				  handleProductAction={handleProductAction}

                />
                {sellerIdFil && (
                  <div className="mt-3 flex position-relative bg-gray-light text-gray-700 w-max-content p-1  d-flex justify-content-between align-items-start rounded">
                    {sellerIdFil}
                    <img
                      src={cancleGrayIcon}
                      onClick={() => handleClear("sellerId")}
                      height="10"
                      className="mt-1 ms-1 cursor"
                      width="10"
                      alt="cancel icon"
                    />
                  </div>
                )}
              </div>
            )}
          </div>
          <div className="mt-3 table-responsive">
            <PrimaryTable
              columns={columns}
              data={data}
              keyField="seller_product_id"
              responsive
              selectRow={selectRow}
              bordered={false}
            />
            {productApprovalData?.length > 0 && pageCount > 1 && (
              <PrimaryPagination
                pageNo={pageNo}
                pageCount={pageCount}
                setPageNo={setPageNo}
              />
            )}
          </div>
        </CardBody>
        {showDeleteModal && (
          <ProductRejectPopup
            isOpen={showDeleteModal}
            title={selectedTitle}
            handleChange={e => {
              setReason(e.target.value);
            }}
            reason={reason}
            handleSave={() => {
				if(actionType == "reject") {
					handleSellerProduct(2)
				}
				else {
					handleSave()
				}
			}}
			loading={loading}
            handleClose={handleClose}
          />
        )}
      </Card>
    </div>
  );
}

export default ProductsApproval;
