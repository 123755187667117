import PrimaryTable from "../PrimaryTable";
import PrimaryPopup from "./PrimaryPopup";
import React from "react";
import { useState, useEffect } from "react";
import PrimaryPagination from "../Pagination/PrimaryPagination";
import { getAllComments } from "services/WithdrawRequestService";
import ProgressPopup from "./ProgressPopup";
import { Button } from "reactstrap";
import { submitWithdrawComment } from "services/WithdrawRequestService";
import PrimaryToast from "../Toast/ToastMessage";
import { convertUtcDateToLocal } from "utils/date";


const SellerCommentsPopup = ({
  isOpen,
  handleClose,
  sellerId,
  withdraw_id,
}) => {
  const [loading, setLoading] = useState(false);
  const [commentsData, setCommentsData] = useState([]);
  const [pageCount, setPageCount] = useState();
  const [pageNo, setPageNo] = useState(1);
  const [comment, setComment] = useState("");

  useEffect(() => {
    setLoading(true);
    getAllComments(pageNo, sellerId, withdraw_id)
      .then(res => {
        setCommentsData(res?.data?.data);
        setPageCount(res?.data?.last_page);
      })
      .catch(err => {
        console.log(err);
      })
      .finally(res => {
        setLoading(false);
      });
  }, [pageNo]);

  const columns = [
    {
      dataField: "comment",
      text: "Comment",
	  formatter: (cell, row) => <div className="font-semi-bold" style={{width:"300px", whiteSpace:'normal',wordBreak:"break-word"}}>{row?.comment}</div>,
    },
    {
      dataField: "comment_by",
      text: "Commented By",
    },
    {
      dataField: "created_at",
      text: "Created By",
      formatter: (cell, row) => <div>{convertUtcDateToLocal(row?.created_at)}</div>,
    },
  ];

  const data = commentsData?.map((item,index) => {
    return {
      ...item,
      index:index+1
    };
  });

  const handleSubmitComment = e => {
    const data = {
      seller_id: sellerId,
      comment,
      withdraw_id,
    };
    setLoading(true);
    submitWithdrawComment(data)
      .then(res => {
        getAllComments(pageNo, sellerId, withdraw_id)
          .then(res => {
            setCommentsData(res?.data?.data);
            setPageCount(res?.data?.last_page);
          })
          .catch(err => {
            console.log(err);
          });
        setComment("");
      })
      .catch(err => {
        console.log(err);
        PrimaryToast({
          type: "error",
          message: Array.isArray(err?.response?.data?.message)
            ? err?.response?.data?.message[0]
            : err?.response?.data?.message,
        });
      })
      .finally(res => {
        setLoading(false);
      });
  };
  return (
    <PrimaryPopup
      isOpen={isOpen}
      handleClick={handleClose}
      title={"Comments"}
      isScrollable={false}
      className="d-flex justify-content-center align-items-center h-100 w-100 comment-popup-container"
    >
      {loading && <ProgressPopup label={"Loading..."} />}

	    <div className="mb-3">
          <div className="mb-3">
            <label htmlFor="createComment" className="form-label">
              Comment
            </label>
            <textarea
              className="form-control"
              id="createComment"
              placeholder="Write Comment"
              value={comment}
              rows="3"
              onChange={e => {
                setComment(e.target.value);
              }}
            ></textarea>
          </div>
          <div className="d-flex justify-content-end">
            <Button color="primary" disabled={loading} onClick={handleSubmitComment}>
              Save
            </Button>
          </div>
        </div>

      <div
        className="overflow-y-auto table-responsive"
        style={{ maxHeight: "600px" }}
      >
        <PrimaryTable
          data={data}
          columns={columns}
          bordered={false}
          keyField="index"
        />
        {commentsData?.length > 0 && pageCount > 1 && (
          <PrimaryPagination
            pageNo={pageNo}
            pageCount={pageCount}
            setPageNo={setPageNo}
          />
        )}
      </div>
    </PrimaryPopup>
  );
};
export default SellerCommentsPopup;
