import React, { useState, useEffect } from "react";
import { Button, Card, CardBody, Container } from "reactstrap";
import deleteIcon from "../../assets/images/delete-icon-white.svg";
import tickIcon from "../../assets/images/tick-icon.svg";
import rectangeArrow from "../../assets/images/rectangle-arrow.svg";
import TopHeading from "components/Common/TopHeading";
import { useNavigate } from "react-router-dom";
import {
  getALlRefundApproval,
  deleteRefunds,
} from "services/RefundApprovalService";
import ProgressPopup from "components/Common/Popups/ProgressPopup";
import PrimaryTable from "components/Common/PrimaryTable";
import PrimaryPagination from "components/Common/Pagination/PrimaryPagination";
import DeleteModal from "components/Common/Toast/DeleteModal";
import PrimaryToast from "components/Common/Toast/ToastMessage";
import { refundApproval } from "services/RefundApprovalService";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { setPageTitle } from "utils/pageTitle";
import { openLinkInNewTab } from "utils/linkHandling";
import useToggle from "hooks/useToggle";
import PrimarySearch from "components/Common/PrimarySearch";
import { convertUtcDateToLocal } from "utils/date";

function OrderRefunds() {
  const [refundApprovals, setRefundApprovals] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [pageNo, setPageNo] = useState(1);
  const [pageCount, setPageCount] = useState();
  const [loading, setLoading] = useState(false);
  const [isDelete, setIsDelete] = useToggle(false);
  const [selectedId, setSelectedId] = useState();
  const [selectedValues, setSelectedValues] = useState([]);
  const [searchVal, setSearchVal] = useState(null);
  const [actionType, setActionType] = useState("");

  const history = useNavigate();
  const user = useSelector(state => state.auth.user);
  const permissions = user?.permissions?.map(item => item.name);
  const params = new URLSearchParams(window?.location?.search);

  useEffect(() => {
    setPageTitle("Refund Approvals- Inspire Uplift");
  }, []);

  useEffect(() => {
    const page = params?.get("page") || undefined;
    const searchParam = params?.get("str") || undefined;
    setPageNo(page || 1);
    setSearchVal(searchParam || "");
    setLoading(true);
    getALlRefundApproval(page, searchParam)
      .then(res => {
        setPageCount(res.data.last_page);
        let selectedData = res.data.data?.map(item => {
          return {
            refund_id: item.id,
            refundTo:
              item?.walletAmount === item?.order_subtotal ? "wallet" : "card",
            internal_refund: false,
          };
        });
        setRefundApprovals(res.data.data);
        setSelectedValues(selectedData);
      })
      .catch(err => {
        console.log(err);
      })
      .finally(res => {
        setLoading(false);
      });
  }, [window?.location?.search]);

  const actionSection = (cellContent, row) => {
    return (
      <div className="d-flex align-items-center">
        <Button
          color="primary"
          className="d-flex align-items-center"
          onClick={() =>
            openLinkInNewTab(`/orders/details/${row?.order_id}`, "_blank")
          }
        >
          <img src={rectangeArrow} />
          <div className="ms-2">Order Detail</div>
        </Button>
        {permissions?.includes("order-refund-delete") && (
          <Button
            color="danger"
            className="ms-2"
            onClick={() => {
              setIsDelete(!isDelete);
              setSelectedId(row.id);
              setSelectedRowKeys([]);
              setActionType("reject");
            }}
          >
            <img src={deleteIcon} />
          </Button>
        )}
      </div>
    );
  };

  const columns = [
    {
      dataField: "order_id",
      text: "Order Number",
      formatter: (cell, row) => (
        <div className="font-semi-bold">{row.order_id}</div>
      ),
    },
    {
      dataField: "created_at",
      text: "Date",
      formatter: (cell, row) => (
        <div className="">{convertUtcDateToLocal(row?.created_at, "DD-MM-YYYY")}</div>
      ),
    },
    {
      dataField: "created_by",
      text: "Created By",
    },
    {
      dataField: "walletAmount",
      text: "Refund To",
      formatter: (cellContent, row) => {
        return (
          <select
            className="rounded w-100 d-flex justify-content-between align-items-center p-2 refund-approval-filter"
            onChange={e => handleRefundToChange(row.id, e.target.value)}
            name="refundTo"
          >
            {row.walletAmount !== row.order_subtotal && (
              <option value="card">Card</option>
            )}
            <option value={"wallet"}>Wallet</option>
          </select>
        );
      },
    },
    {
      dataField: "action",
      text: "Action",
      formatter: actionSection,
    },
    {
      dataField: "internal_refund",
      text: "Internal Refund",
      formatter: (cell, row) => (
        <div>
          <input
            type="checkbox"
            onChange={e => handleInternalRefundChange(row.id, e.target.checked)}
            name="internal_refund"
            className="mt-1"
          />
        </div>
      ),
    },
  ];

  const handleRefundToChange = (itemId, newName) => {
    setSelectedValues(previous => {
      const existingItemIndex = previous.findIndex(
        item => item.refund_id === itemId
      );

      if (existingItemIndex !== -1) {
        const updatedValues = [...previous];
        updatedValues[existingItemIndex] = {
          ...updatedValues[existingItemIndex],
          refundTo: newName,
        };
        return updatedValues;
      } else {
        return [
          ...previous,
          {
            refund_id: itemId,
            refundTo: newName || "wallet", // Set a default value for refundTo if it's not provided
            internal_refund: false, // Default value for internal_refund
          },
        ];
      }
    });
  };

  const handleInternalRefundChange = (itemId, isChecked) => {
    setSelectedValues(previous => {
      const existingItemIndex = previous.findIndex(
        item => item.refund_id === itemId
      );

      if (existingItemIndex !== -1) {
        const updatedValues = [...previous];
        updatedValues[existingItemIndex] = {
          ...updatedValues[existingItemIndex],
          internal_refund: isChecked,
        };
        return updatedValues;
      } else {
        return [
          ...previous,
          {
            refund_id: itemId,
            refundTo: "wallet", // Default value for refundTo
            internal_refund: isChecked,
          },
        ];
      }
    });
  };

  const data = refundApprovals?.map(item => {
    return {
      ...item,
    };
  });

  const handleDelete = () => {
    const ids = selectedId ? [selectedId] : selectedRowKeys;
    const filterData = selectedId
      ? refundApprovals?.filter(item => item.id !== selectedId)
      : refundApprovals?.filter(item => !selectedRowKeys?.includes(item.id));
    toast.dismiss();
    setLoading(true);
    deleteRefunds(ids)
      .then(res => {
        setSelectedId("");
        setIsDelete(!isDelete);
        setSelectedRowKeys([]);
        setActionType("");
        if (filterData?.length == 0 && pageNo > 1) {
          params.set("page", pageNo - 1);
          let newUrl = `${window?.location?.pathname}?${params.toString()}`;
          history(newUrl, { replace: true });
        } else {
          getALlRefundApproval(pageNo, searchVal)
            .then(res => {
              setRefundApprovals(res.data.data);
              setPageCount(res.data.last_page);
            })
            .catch(err => {
              console.log(err);
            })
            .finally(res => setLoading(false));
        }

        PrimaryToast({ type: "success", message: res?.data?.message });
      })
      .catch(err => {
        setLoading(false);
        console.log(err);
        PrimaryToast({ type: "error", message: err?.response?.data?.message });
      });
  };

  const handleOnSelectAll = (isSelected, rows) => {
    if (isSelected) {
      setSelectedRowKeys(rows.map(row => row.id));
    } else {
      setSelectedRowKeys([]);
    }
  };

  const selectRow = {
    mode: "checkbox",
    onSelect: (row, isSelect, rowIndex, e) => {
      if (isSelect) {
        setSelectedRowKeys([...selectedRowKeys, row.id]);
      } else {
        setSelectedRowKeys(selectedRowKeys.filter(key => key !== row.id));
      }
    },
    onSelectAll: handleOnSelectAll,
    selected: selectedRowKeys,
  };

  const handleApproved = () => {
    const selectedRows = selectedValues?.filter(item => {
      return selectedRowKeys?.includes(item.refund_id);
    });
    const filterData = refundApprovals?.filter(
      item => !selectedRowKeys.includes(item.id)
    );
    toast.dismiss();
    setLoading(true);
    refundApproval(selectedRows)
      .then(res => {
        PrimaryToast({
          type: "success",
          message: "Refund Approve successfully",
        });
        setSelectedRowKeys([]);
        setSelectedValues();
        setIsDelete(false);
        setActionType("");
        if (filterData?.length == 0 && pageNo > 1) {
          params.set("page", pageNo - 1);
          let newUrl = `${window?.location?.pathname}?${params.toString()}`;
          history(newUrl, { replace: true });
        } else {
          getALlRefundApproval(pageNo, searchVal)
            .then(res => {
              setPageCount(res.data.last_page);
              let selectedData = res.data.data?.map(item => {
                return {
                  refund_id: item.id,
                  refundTo:
                    item?.walletAmount === item?.order_subtotal
                      ? "wallet"
                      : "card",
                  internal_refund: false,
                };
              });
              setLoading(false);
              setRefundApprovals(res.data.data);
              setSelectedValues(selectedData);
            })
            .catch(err => {
              setLoading(false);
              console.log(err);
            });
        }
      })
      .catch(err => {
        console.log(err);
        setLoading(false);
        PrimaryToast({
          type: "error",
          message: Array.isArray(err?.response?.data?.message)
            ? err?.response?.data?.message[0]
            : err?.response?.data?.message,
        });
      });
  };

  const handleAction = () => {
    if (actionType === "approve") {
      handleApproved();
    } else if (actionType === "reject") {
      handleDelete();
    }
  };

  const handleKeyPress = e => {
    if (e.key == "Enter") {
      if (searchVal) {
        params.set("str", searchVal);
      } else {
        params.delete("str");
      }
      if (params.get("page")) {
        params.delete("page");
      }
      history(`/orders/refund-approvals?${params?.toString()}`);
    }
  };

  return (
    <div className="page-content">
      {loading && <ProgressPopup label={"loading..."} />}
      {isDelete && (
        <DeleteModal
          title={
            actionType === "reject"
              ? "Are you sure you want to delete this Refund?"
              : "Are you sure you want to approve this Refund?"
          }
          onConfirm={handleAction}
          onCancel={() => {
            setSelectedId("");
            setSelectedRowKeys([]);
            setIsDelete(!isDelete);
            setActionType("");
          }}
        />
      )}
      <Container fluid>
        <div className="mb-4">
          <TopHeading heading={"Refund Approvals"} />
        </div>

        <Card>
          <CardBody>
            <div className="mt-3">
              <PrimarySearch
                handleKeyPress={handleKeyPress}
                handleChange={e => setSearchVal(e.target.value)}
                placeholder={"Search by Order Number"}
                value={searchVal}
              />
            </div>
            {selectedRowKeys?.length > 0 && (
              <div className="d-flex justify-content-end align-items-center">
                {permissions?.includes("order-refund-delete") && (
                  <Button className="danger-btn" disabled={loading}>
                    <img src={deleteIcon} alt="delete icon" />
                    <span
                      className="ps-2"
                      onClick={() => {
                        setActionType("reject");
                        setIsDelete(!isDelete);
                      }}
                    >
                      Delete
                    </span>
                  </Button>
                )}
                {permissions?.includes("order-refund-approve") && (
                  <Button
                    disabled={loading}
                    className="approve-btn"
                    onClick={() => {
                      setActionType("approve");
                      setIsDelete(!isDelete);
                    }}
                  >
                    <img src={tickIcon} />
                    <span className="ps-2">Approve</span>
                  </Button>
                )}
              </div>
            )}
            <div className="mt-3">
              <PrimaryTable
                columns={columns}
                data={data}
                bordered={false}
                keyField="id"
                selectRow={selectRow}
              />
              {refundApprovals?.length > 0 && pageCount > 1 && (
                <PrimaryPagination
                  pageCount={pageCount}
                  pageNo={pageNo}
                  setPageNo={setPageNo}
                />
              )}
            </div>
          </CardBody>
        </Card>
      </Container>
    </div>
  );
}

export default OrderRefunds;
