import React, { useState, useEffect, useMemo } from "react";
import TopHeading from "components/Common/TopHeading";
import PrimarySearch from "components/Common/PrimarySearch";
import { CardBody, Card, Button } from "reactstrap";
import { useNavigate } from "react-router-dom";

import tickIcon from "assets/images/tick-icon.svg";
import cancleIcon from "assets/images/cancle-icon.svg";
import {
  getWithDrawRequestList,
  handleWithdrawApprove,
  handleWithdrawReject,
  exportWithdraws,
} from "services/WithdrawRequestService";
import PrimaryTable from "components/Common/PrimaryTable";
import ProgressPopup from "components/Common/Popups/ProgressPopup";
import PrimaryPagination from "components/Common/Pagination/PrimaryPagination";
import ReviewWithdrawPopup from "components/Common/Popups/ReviewWithdrawPopup";
import PrimaryToast from "components/Common/Toast/ToastMessage";
import DeleteModal from "components/Common/Toast/DeleteModal";
import DateRangePicker from "components/Common/DateRangePicker";
import moment from "moment";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { setPageTitle } from "utils/pageTitle";
import WithdrawRejectPopup from "components/Common/Popups/WithdrawRejectPopup";
import useToggle from "hooks/useToggle";
import Select from "react-select";
import { getAllPayoutMethodList } from "services/SellerPayoutApprovals";
import ResetButton from "components/Common/ResetButton";
import ExportButton from "components/Common/ExportButton";
import { convertLocalDatesToUTC, convertUtcDateToLocal } from "utils/date";

function WithdrawRequests() {
  const [options, setOptions] = useState([]);
  const [withdrawRequest, setWithdrawRequest] = useState([]);
  const [pageNo, setPageNo] = useState(1);
  const [pageCount, setPageCount] = useState();
  const [isPayoneer, setIsPayoneer] = useToggle(false);
  const [loading, setLoading] = useState(false);
  const [transactionId, setTransactionId] = useState("");
  const [payoneerId, setPayoneerId] = useState();
  const [showDeleteModal, setShowDeleteModal] = useToggle(false);
  const [selectedId, setSelectedId] = useState();
  const [status, setStatus] = useState("");
  const [reviewStatus, setReviewStatus] = useState("");
  const [withdrawReason, setWithdrawReason] = useState("");
  const [isWithdrawReject, setIsWithdrawReject] = useToggle(false);
  const [payoutStatus, setPayoutStatus] = useState([]);
  const [actionType, setActionType] = useState("");

  const params = new URLSearchParams(window.location.search);
  const paramsLength = Array.from(params.keys()).length;
  const [dates, setDates] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);
  const [showCalendar, setShowCalendar] = useState(false);
  const [searchVal, setSearch] = useState(null);
  const user = useSelector(state => state?.auth?.user);
  const hasWithdrawApprovePer = user.permissions?.some(
    p => p.name === "withdraw-approve"
  );
  const hasWithdrawRejectedPer = user?.permissions?.some(
    p => p.name === "withdraw-reject"
  );
  const hasExportWithdraw = user?.permissions?.some(
    p => p.name === "withdraw-approve"
  );

  const customStyles = {
    control: provided => ({
      ...provided,
      borderRadius: "999px",
    }),
  };

  const getAllPayout = async () => {
    try {
      const res = await getAllPayoutMethodList();

      const newOptions = [
        ...res?.data?.payouts,
        { name: "Paypal and Bank Transfer", id: "paypal_and_wire" },
      ];
      const customTypes = newOptions
        .filter(
          item =>
            item.name?.toLowerCase() !== "paypal" &&
            item.name?.toLowerCase() !== "bank transfer"
        )
        .map(item => ({ label: item.name, value: item.id }));
      setOptions(customTypes);
      return customTypes; // Return the fetched options
    } catch (error) {
      console.log(error);
    }
  };

  const getOptions = useMemo(() => {
    return getAllPayout();
  }, []);

  useEffect(() => {
    setPageTitle("Seller Withdraw Requests - Inspire Uplift");
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const fetchPayoutList = await getOptions;
      const statusParam = params?.get("status");
      const startDate = params?.get("start");
      const endDate = params?.get("end");
      const reviewParam = params?.get("review_status");
      const search = params?.get("str") || null;
      const page = params?.get("page") || undefined;
      setSearch(search || "");
      setPageNo(page || 1);

      let status =
        statusParam == "Pending"
          ? "0"
          : statusParam == "Approved"
          ? 1
          : statusParam == "Rejected"
          ? 2
          : statusParam == "Processing"
          ? 3
          : statusParam == "Cancelled"
          ? 4
          : "";
      setStatus(status);

      let review =
        reviewParam == "Approved"
          ? 1
          : reviewParam == "Rejected"
          ? 2
          : reviewParam == "Pending"
          ? "0"
          : "";
      setReviewStatus(review);
      const eventStartDate = moment(
        startDate || new Date(),
        "DD-MM-YYYY"
      ).toDate();
      const eventEndDate = moment(endDate || new Date(), "DD-MM-YYYY").toDate();
      setDates([
        {
          startDate: eventStartDate,
          endDate: eventEndDate,
          key: "selection",
        },
      ]);
      const { utcStartDate, utcEndDate } = convertLocalDatesToUTC(
        eventStartDate,
        eventEndDate
      );
      const payoutTypeParam = params?.get("payout_type") || undefined;
      let payoutStatus;
      if (payoutTypeParam) {
        let findPayoutType = fetchPayoutList?.find(
          item => item.label == payoutTypeParam
        );
        payoutStatus = findPayoutType?.label;

        setPayoutStatus(findPayoutType);
      } else {
        setPayoutStatus([]);
      }

      let applyFilter = {
        status: status?.toString() || undefined,
        review_status: review?.toString() || undefined,
        start_date: startDate ? utcStartDate : undefined,
        end_date: endDate ? utcEndDate : undefined,
        payout_method:
          payoutStatus == "Paypal and Bank Transfer"
            ? "paypal_and_wire"
            : payoutStatus,
      };
      setLoading(true);
      getAllWithdraws(page, search, applyFilter);
    };

    fetchData();
  }, [window?.location?.search]);

  const getAllWithdraws = (page, search, status) => {
    getWithDrawRequestList(page, search, status)
      .then(res => {
        if (res.data.withdraws?.data?.length == 0 && page > 1) {
          params.set("page", page - 1);
          const newUrl = `${window.location.pathname}?${params.toString()}`;
          history(newUrl);
        } else {
          setWithdrawRequest(res.data.withdraws?.data);
          setPageCount(res.data.withdraws?.last_page);
        }
      })
      .catch(err => console.log(err))
      .finally(res => setLoading(false));
  };

  const history = useNavigate();

  const columns = [
    {
      dataField: "store_name",
      text: "Store Name",
      formatter: (cell, row) => <div>{row.seller.business_name}</div>,
    },
    {
      dataField: "amount",
      text: "Amount",
      formatter: (cell, row) => <div>${row.amount}</div>,
    },
    {
      dataField: "payment_type",
      text: "Payout Method",
      formatter: (cell, row) => (
        <div>
          {row?.payment_type == "hyperwallet_paypal"
            ? "Paypal"
            : row?.payment_type == "hyperwallet_wire"
            ? "Wire"
            : row.payment_type}
        </div>
      ),
    },
    {
      dataField: "review_status",
      text: "Review Status",
      formatter: (cell, row) => {
        let reviewStatus;
        switch (row.review_status) {
          case 1:
            reviewStatus = "Approved";
            break;
          case 2:
            reviewStatus = "Rejected";
            break;
          default:
            reviewStatus = "Pending";
        }
        return (
          <div
            className={`${
              reviewStatus === "Approved"
                ? "success-status"
                : reviewStatus === "Pending"
                ? "warning-status"
                : reviewStatus === "Rejected"
                ? "danger-status"
                : ""
            } status w-max-content`}
          >
            {reviewStatus}
          </div>
        );
      },
    },
    {
      dataField: "status",
      text: "Status",
      formatter: (cell, row) => {
        let status;
        switch (row.status) {
          case 1:
            status = "Approved";
            break;
          case 2:
            status = "Rejected";
            break;
          case 3:
            status = "Processing";
            break;
          case 4:
            status = "Cancelled";
            break;

          default:
            status = "Pending";
        }
        return (
          <div
            className={`${
              status === "Approved"
                ? "success-status"
                : status === "Pending" || status === "Processing"
                ? "warning-status"
                : status === "Rejected" || status === "Cancelled"
                ? "danger-status"
                : ""
            } status w-max-content`}
          >
            {status}
          </div>
        );
      },
    },
    {
      dataField: "created_at",
      text: "Date",
      formatter: (cell, row) => <div>{row.created_at ? convertUtcDateToLocal(row?.created_at,"DD-MM-YYYY") : null}</div>,
    },
    {
      dataField: "action",
      text: "Action",
      formatter: (cell, row) => (
        <div
          className={`d-flex  align-items-center ${
            (row.status === 1 ||
              row.status === 2 ||
              row?.is_payout_enabled == 0 ||
              row?.status == 4) &&
            "d-none"
          } `}
        >
          {hasWithdrawRejectedPer && (
            <div
              className="action-btn delete-btn-bg me-1 cursor-pointer"
              onClick={e => {
                e.stopPropagation();
                setSelectedId(row.id);
                setShowDeleteModal(true);
                setActionType("reject");
              }}
            >
              <img src={cancleIcon} className="" alt="delete icon" />
            </div>
          )}
          {hasWithdrawApprovePer && (
            <>
              {row?.payment_type === "payoneer" ? (
                <div
                  className="action-btn approve-btn-bg cursor-pointer"
                  onClick={e => {
                    e.stopPropagation();
                    setPayoneerId(row.id);
                    setIsPayoneer(true);
                  }}
                >
                  <img src={tickIcon} className="" alt="edit icon" />
                </div>
              ) : (
                <div
                  className="action-btn approve-btn-bg cursor-pointer"
                  onClick={e => {
                    e.stopPropagation();
                    setSelectedId(row.id);
                    setShowDeleteModal(true);
                    setActionType("accept");
                  }}
                >
                  <img src={tickIcon} className="" alt="edit icon" />
                </div>
              )}
            </>
          )}
        </div>
      ),
    },
  ];

  const data = withdrawRequest?.map(item => {
    return {
      ...item,
    };
  });

  const handleSearch = e => {
    const value = e.target.value;
    setSearch(value);
  };

  const handleKeyPress = e => {
    if (e.key === "Enter") {
      handleFilter();
    }
  };

  const updatedWithdrawData = filterData => {
    const startDate = params?.get("start");
    const endDate = params?.get("end");
    const { utcStartDate, utcEndDate } = convertLocalDatesToUTC(
      startDate,
      endDate
    );
    let applyFilter = {
      status: status || undefined,
      review_status: reviewStatus || undefined,
      start_date: startDate ? utcStartDate : undefined,
      end_date: endDate ? utcEndDate : undefined,
      payout_method:
        payoutStatus?.label == "Paypal and Bank Transfer"
          ? "paypal_and_wire"
          : payoutStatus?.label,
    };
    if (filterData?.length == 0 && pageNo > 1) {
      params.set("page", pageNo - 1);
      const newUrl = `${window.location.pathname}?${params.toString()}`;
      history(newUrl);
    } else {
      getAllWithdraws(pageNo, searchVal, applyFilter);
    }
  };

  const handleAction = (e, actionStatus, id) => {
    e.stopPropagation();
    const data = {
      seller_withdraw_ids: [id],
    };

    const filterData = withdrawRequest?.filter(item => item.id !== id);
    setLoading(true);
    toast.dismiss();
    if (actionStatus === 1) {
      handleWithdrawApprove(data)
        .then(res => {
          PrimaryToast({ type: "success", message: res?.data?.message });
          updatedWithdrawData(filterData);
          setShowDeleteModal(false);
        })
        .catch(err => {
          setLoading(false);
          PrimaryToast({
            type: "error",
            message: Array.isArray(err?.response?.data?.message)
              ? err?.response?.data?.message[0]
              : err?.response?.data?.message,
          });
          console.log(err);
        });
    }
    if (actionStatus == 2) {
      handleWithdrawReject(data, withdrawReason)
        .then(res => {
          PrimaryToast({ type: "success", message: res?.data?.message });
          updatedWithdrawData(filterData);
          setIsWithdrawReject(false);
          setWithdrawReason("");
        })
        .catch(err => {
          setLoading(false);
          PrimaryToast({
            type: "error",
            message: Array.isArray(err?.response?.data?.message)
              ? err?.response?.data?.message[0]
              : err?.response?.data?.message,
          });
          console.log(err);
        });
    }
    setActionType("");
  };

  const handlePayoneerRequest = () => {
    const data = {
      seller_withdraw_ids: [payoneerId],
      transaction: transactionId,
    };
    setLoading(true);
    const filterData = withdrawRequest?.filter(item => item.id !== payoneerId);
    toast.dismiss();
    handleWithdrawApprove(data)
      .then(res => {
        PrimaryToast({ type: "succcess", message: res?.data?.message });
        setSelectedId("");
        setShowDeleteModal(false);
        updatedWithdrawData(filterData);
        setIsPayoneer(false);
        setPayoneerId("");
        setTransactionId("");
      })
      .catch(err => {
        console.log(err);
        setLoading(false);
        PrimaryToast({ type: "error", message: err?.response?.data?.message });
      });
  };

  const handleDatePicker = item => {
    setDates([item.selection]);
  };

  const handleFilter = () => {
    let startDate = moment(dates[0].startDate).format("DD-MM-YYYY");
    let endDate = moment(dates[0].endDate).format("DD-MM-YYYY");
    const page = params.get("page");
    if (status) {
      params.set(
        "status",
        status == 1
          ? "Approved"
          : status == 2
          ? "Rejected"
          : status == 3
          ? "Processing"
          : status == 4
          ? "Cancelled"
          : status == 0
          ? "Pending"
          : ""
      );
    } else {
      params.delete("status");
    }

    if (reviewStatus) {
      params.set(
        "review_status",
        reviewStatus == 1
          ? "Approved"
          : reviewStatus == 2
          ? "Rejected"
          : reviewStatus == 0
          ? "Pending"
          : ""
      );
    } else {
      params?.delete("review_status");
    }
    if (Object.keys(payoutStatus)?.length > 0) {
      params?.set("payout_type", payoutStatus?.label);
    }
    if (searchVal) {
      params.set("str", searchVal);
    } else {
      params.delete("str", searchVal);
    }

    if (page) {
      params.delete("page");
    }

    params.set("start", startDate);
    params.set("end", endDate);

    // Update the URL with the new search parameters
    const newUrl = `${window.location.pathname}?${params.toString()}`;
    history(newUrl, { replace: true });
  };

  const handleRejectWithdraw = e => {
    handleAction(e, 2, selectedId);
  };

  const handleSelectChange = selected => {
    setPayoutStatus(selected);
  };

  const downloadCSV = async () => {
    const { utcStartDate, utcEndDate } = convertLocalDatesToUTC(
      dates[0].startDate,
      dates[0].endDate
    );
    const response = await exportWithdraws(
      utcStartDate,
      utcEndDate,
      payoutStatus?.label
    );
    return response;
  };

  return (
    <div className="page-content">
      {showDeleteModal && (
        <DeleteModal
          title={
            actionType == "reject"
              ? "Are you sure to reject this withdraw?"
              : actionType == "accept"
              ? "Are you sure you want to approve this withdraw?"
              : ""
          }
          onConfirm={() => {
            setShowDeleteModal(false);
            actionType == "reject"
              ? setIsWithdrawReject(!isWithdrawReject)
              : handleAction(event, 1, selectedId);
          }}
          onCancel={() => {
            setSelectedId("");
            setShowDeleteModal(!showDeleteModal);
            setActionType("");
          }}
        />
      )}
      {loading && <ProgressPopup label={"Loading..."} />}
      <div className="mb-3">
        <TopHeading heading={"Withdraw Requests"} />
      </div>
      <Card>
        <CardBody>
          <div className="d-flex justify-content-between align-items-center mb-3">
            <div className="me-2 d-flex align-items-start">
              <div className="mt-3">
                <PrimarySearch
                  handleKeyPress={handleKeyPress}
                  value={searchVal}
                  placeholder={"Search by name"}
                  handleChange={handleSearch}
                />
              </div>
              <div className="d-flex flex-column">
                <div className="d-flex flex-wrap">
                  <div className="position-relative ms-2 mt-3">
                    <div
                      className="form-control cursor-pointer rounded-full"
                      style={{ color: "#74788D", width: "max-content" }}
                      onClick={() => setShowCalendar(true)}
                    >
                      {moment(dates[0].startDate).format("DD MMM YYYY")} -{" "}
                      {moment(dates[0].endDate).format("DD MMM YYYY")}
                    </div>

                    {showCalendar && (
                      <>
                        <div
                          className="position-fixed top-0 start-0 w-100 h-100"
                          style={{ zIndex: "10" }}
                          onClick={() => setShowCalendar(false)}
                        ></div>
                        <DateRangePicker
                          dates={dates}
                          handleDatePicker={handleDatePicker}
                          handleSubmit={() => setShowCalendar(false)}
                          maxDate={new Date()}
                        />
                      </>
                    )}
                  </div>
                  <div style={{ minWidth: "250px" }} className="mt-3 ms-1">
                    <Select
                      isMulti={false}
                      options={options}
                      onChange={handleSelectChange}
                      value={payoutStatus}
                      name={"payout_method"}
                      className="react-select-container"
                      styles={customStyles}
                      placeholder={"Search By Payout Method Type"}
                    />
                  </div>
                  <select
                    className="primary-select-option ms-1 mt-3"
                    name="status"
                    value={status || ""}
                    onChange={e => setStatus(e.target.value)}
                    onKeyPress={handleFilter}
                  >
                    <option value={""}>Search By Status</option>
                    <option value={0}>Pending</option>
                    <option value={1}>Approved</option>
                    <option value={2}>Rejected</option>
                    <option value={3}>Processing</option>
                    <option value={4}>Cancelled</option>
                  </select>
                  <select
                    className="primary-select-option ms-1 mt-3"
                    name="review_status"
                    value={reviewStatus || ""}
                    onChange={e => {
                      setReviewStatus(e.target.value);
                    }}
                    onKeyPress={handleFilter}
                  >
                    <option value={""}>Search By Review Status</option>
                    <option value={0}>Pending</option>
                    <option value={1}>Approved</option>
                    <option value={2}>Rejected</option>
                  </select>
                  <Button
                    color="primary"
                    className={`btn-rounded  ms-1 mt-3`}
                    onClick={handleFilter}
                  >
                    Apply Filter
                  </Button>
                  {paramsLength > 0 && (
                    <div className="d-flex items-center mt-3">
                      <ResetButton />
                    </div>
                  )}
                  {payoutStatus?.label == "Payoneer" && hasExportWithdraw && (
                    <div className="mt-3 ms-3">
                      <ExportButton
                        isLoading={loading}
                        setIsLoading={setLoading}
                        fetchData={downloadCSV}
                      />
                    </div>
                  )}
                </div>
              </div>
              <div></div>
            </div>
          </div>
          <div>
            <PrimaryTable
              columns={columns}
              data={data}
              responsive
              bordered={false}
              keyField="id"
              isPermission={true}
              rowUrl={"/seller/withdraw-requests"}
            />
          </div>

          {withdrawRequest?.length > 0 && pageCount > 1 && (
            <PrimaryPagination
              pageCount={pageCount}
              pageNo={pageNo}
              CodeWhisperer
              setPageNo={setPageNo}
            />
          )}
          {isWithdrawReject && (
            <WithdrawRejectPopup
              loading={loading}
              title={"Withdraw Reject"}
              handleChange={e => {
                setWithdrawReason(e.target.value);
              }}
              reason={withdrawReason}
              isOpen={isWithdrawReject}
              handleClose={() => {
                setWithdrawReason("");
                setIsWithdrawReject(!isWithdrawReject);
              }}
              handleRequest={handleRejectWithdraw}
            />
          )}
          {isPayoneer && (
            <ReviewWithdrawPopup
              loading={loading}
              title={"Payoneer/USDT Information"}
              handleChange={e => setTransactionId(e.target.value)}
              handleRequest={handlePayoneerRequest}
              type="payoneer"
              review={transactionId}
              isOpen={isPayoneer}
              handleClose={() => {
                setTransactionId();
                setIsPayoneer(!isPayoneer);
              }}
            />
          )}
        </CardBody>
      </Card>
    </div>
  );
}

export default WithdrawRequests;
