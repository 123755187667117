import React, { useState, useEffect } from "react";
import moment from "moment";
import { Button, Card, CardBody } from "reactstrap";
import TopHeading from "components/Common/TopHeading";
import { useParams, useSearchParams } from "react-router-dom";
import { getSalesReferralsProductsDetail } from "services/ReportsServices";
import DateRangePicker from "components/Common/DateRangePicker";
import ProgressPopup from "components/Common/Popups/ProgressPopup";
import PrimaryTable from "components/Common/PrimaryTable";
import PolygonIcon from "assets/images/polygon.svg";
import PrimaryPagination from "components/Common/Pagination/PrimaryPagination";
import { countOptions } from "utils/constant";
import downwardIcon from "assets/images/downword-white.svg";
import ResetButton from "components/Common/ResetButton";
import { convertLocalDatesToUTC } from "utils/date";

const ReferralProductsDetail = () => {
  const [urlParams, setUrlParams] = useSearchParams();
  const [pageNo, setPageNo] = useState();
  const [pageCount, setPageCount] = useState(1);
  const [loading, setLoading] = useState(false);
  const [productData, setProductData] = useState([]);
  const [dates, setDates] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);
  const [showCalendar, setShowCalendar] = useState(false);
  const { referral } = useParams();
  const [sortInfo, setSortInfo] = useState({ field: "views", order: "desc" });
  const [heading, setHeading] = useState("");
  const [purchasesFilter, setPurchasesFilter] = useState({});
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    let startParam = urlParams?.get("start") || new Date();
    let endParam = urlParams?.get("end") || new Date();
    const pageParam = urlParams?.get("page") || undefined;
    const sortByParam = urlParams?.get("sort_by");
    const sortOfParam = urlParams?.get("sort_of");
    const filterValue = urlParams?.get("filter_value") || undefined;
    const filterType = urlParams?.get("filter_type") || undefined;
    setPageNo(pageParam || 1);
    const eventStartDate = moment(
      startParam || new Date(),
      "DD-MM-YYYY"
    ).toDate();
    const eventEndDate = moment(endParam || new Date(), "DD-MM-YYYY").toDate();
    setDates([
      {
        startDate: eventStartDate,
        endDate: eventEndDate,
        key: "selection",
      },
    ]);
    setSortInfo(prevSortInfo => ({
      ...prevSortInfo,
      order: sortByParam,
      field: sortOfParam,
    }));
    setPurchasesFilter({
      filter_value: filterValue,
      filter_type: filterType,
    });
    const { utcStartDate, utcEndDate } = convertLocalDatesToUTC(
      eventStartDate,
      eventEndDate
    );


    setLoading(true);

    getSalesReferralsProductsDetail(
      utcStartDate,
      utcEndDate,
      pageParam,
      referral,
      sortOfParam,
      sortByParam,
      filterType,
      filterValue
    )
      .then(res => {
        setProductData(res.data.data);
        setPageCount(res?.data?.last_page);
        setHeading(res?.data?.referrer);
      })
      .catch(err => {
        console.log(err);
      })
      .finally(res => {
        setLoading(false);
      });
  }, [urlParams]);

  const columns = [
    {
      dataField: "title",
      text: "Title",
      formatter: (cell, row) => {
        return (
          <a
            className="primary-text product-title-container"
            target="__blank"
            href={
              window.location.hostname.includes(".itdeptiu")
                ? `https://staging.itdeptiu.com${row.url}`
                : `https://www.inspireuplift.com${row.url}`
            }
          >
            {row.title}
          </a>
        );
      },
    },
    {
      dataField: "views",
      text: "Views",
      headerFormatter: column => (
        <div
          className="cursor-pointer"
          onClick={() => handleColumnClick(column)}
        >
          Views{" "}
          {sortInfo.field === "views" && (
            <img
              src={PolygonIcon}
              alt="polygon icon"
              className={`${sortInfo.order == "asc" && "rotate-180"}`}
            />
          )}
        </div>
      ),
      formatter: (cell, row) => {
        return <div>{row?.views}</div>;
      },
    },
    {
      dataField: "cart",
      text: "Add to Cart",
      formatter: (cell, row) => <div>{row.addToCart}</div>,
      headerFormatter: column => (
        <div
          className="cursor-pointer"
          onClick={() => handleColumnClick(column)}
        >
          Add to Cart{" "}
          {sortInfo.field === "cart" && (
            <img
              src={PolygonIcon}
              alt="polygon icon"
              className={`${sortInfo.order == "asc" && "rotate-180"}`}
            />
          )}
        </div>
      ),
    },
    {
      dataField: "purchase",
      text: "Purchased Quantity",
      formatter: (cell, row) => <div>{row?.purchaseCount}</div>,
      headerFormatter: column => (
        <div
          className="cursor-pointer"
          onClick={() => handleColumnClick(column)}
        >
          Purchased Quantity{" "}
          {sortInfo.field === "purchase" && (
            <img
              src={PolygonIcon}
              alt="polygon icon"
              className={`${sortInfo.order == "asc" && "rotate-180"}`}
            />
          )}
        </div>
      ),
    },
    {
      dataField: "revenue",
      text: "Revenue",
      formatter: (cell, row) => <div>${row?.revenue}</div>,
      headerFormatter: column => (
        <div
          className="cursor-pointer"
          onClick={() => handleColumnClick(column)}
        >
          Revenue{" "}
          {sortInfo.field === "revenue" && (
            <img
              src={PolygonIcon}
              alt="polygon icon"
              className={`${sortInfo.order == "asc" && "rotate-180"}`}
            />
          )}
        </div>
      ),
    },
    {
      dataField: "conversion_rate",
      text: "Conversion Rate",
      formatter: (cell, row) => <div>{row?.conversion_rate.toFixed(2)}</div>,
    },
  ];

  const rowData = productData?.map(item => {
    return {
      ...item,
    };
  });

  const handleColumnClick = column => {
    handleFilter(
      "sort_of",
      column.dataField,
      "sort_by",
      column.dataField == sortInfo?.field
        ? sortInfo?.order === "asc"
          ? "desc"
          : "asc"
        : "desc"
    );
  };

  const handleFilter = (key, value, key2, value2) => {
    const existingParams = Object.fromEntries(urlParams?.entries());
    if (key && value) {
      existingParams[key] = value;
      if (key2) {
        existingParams[key2] = value2;
      }
    } else {
      delete existingParams[key];
    }
    if (existingParams?.hasOwnProperty("page")) {
      delete existingParams["page"];
    }
    setUrlParams(existingParams);
  };

  const handleDatePicker = item => {
    setDates([
      {
        startDate: moment(item.selection.startDate)
          .local()
          .startOf("day")
          .toDate(),
        endDate: moment(item.selection.endDate).local().endOf("day").toDate(),
        key: "selection",
      },
    ]);
  };

  const handleApplyFilter = () => {
    const existingParams = Object.fromEntries(urlParams?.entries());
    const currentFilters = {
      ...purchasesFilter,
      start: moment(dates[0].startDate).format("YYYY-MM-DD"),
      end: moment(dates[0].endDate).format("YYYY-MM-DD"),
    };
    Object.entries(currentFilters).forEach(([key, value]) => {
      if (key && value) {
        existingParams[key] = value;
      } else {
        delete existingParams[key];
      }
    });
    if (existingParams?.hasOwnProperty("page")) {
      delete existingParams["page"];
    }
    setUrlParams(existingParams);
  };

  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="page-content">
      {loading && <ProgressPopup label={"Loading..."} />}
      {heading && <TopHeading heading={heading} />}
      <Card className="mt-4">
        <CardBody>
          <div className="d-flex align-items-center">
            <div className="position-relative">
              <div
                className="form-control cursor-pointer rounded-full"
                style={{ color: "#74788D", width: "max-content" }}
                onClick={() => setShowCalendar(true)}
              >
                {moment(dates[0].startDate).format("DD MMM YYYY")} -{" "}
                {moment(dates[0].endDate).format("DD MMM YYYY")}
              </div>

              {showCalendar && (
                <>
                  <div
                    className="position-fixed top-0 start-0 w-100 h-100"
                    style={{ zIndex: "10" }}
                    onClick={() => setShowCalendar(false)}
                  ></div>
                  <DateRangePicker
                    dates={dates}
                    handleDatePicker={handleDatePicker}
                    handleSubmit={() => {
                      setShowCalendar(false);
                    }}
                    // minDate={new Date()}
                    maxDate={new Date()}
                  />
                </>
              )}
            </div>
            <div className="d-flex align-items-center ms-2">
              <div className="input-group rounded-full w-240 me-3">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Purchase value"
                  name="filter_value"
                  value={purchasesFilter?.filter_value || ""}
                  onChange={e =>
                    setPurchasesFilter(pre => {
                      return {
                        ...pre,
                        filter_value: e.target.value,
                      };
                    })
                  }
                />
                {purchasesFilter?.filter_type && (
                  <button type="button" className="btn btn-primary">
                    {purchasesFilter?.filter_type == "equal"
                      ? "="
                      : purchasesFilter?.filter_type == "greater"
                      ? ">"
                      : purchasesFilter?.filter_type == "smaller"
                      ? "<"
                      : ""}
                  </button>
                )}

                <button
                  type="button"
                  className="btn btn-primary dropdown-toggle dropdown-toggle-split"
                  onClick={toggleOpen}
                >
                  <img src={downwardIcon} alt="downword icon" />
                </button>
                <ul
                  className={`dropdown-menu dropdown-menu-end  ${
                    isOpen && "show"
                  }`}
                >
                  {countOptions.map((option, index) => {
                    return (
                      <li
                        className=""
                        key={index}
                        onClick={() => {
                          setPurchasesFilter(pre => {
                            return {
                              ...pre,
                              filter_type: option.value,
                            };
                          });
                          toggleOpen();
                        }}
                      >
                        <span className="dropdown-item fs-6 fw-bold">
                          {option.name}
                        </span>
                      </li>
                    );
                  })}
                </ul>
              </div>{" "}
              <Button color="primary" onClick={handleApplyFilter}>
                Apply
              </Button>
              {urlParams?.size > 0 && <ResetButton />}
            </div>
          </div>

          <div className="table-responsive mt-4">
            <PrimaryTable
              columns={columns}
              data={rowData}
              bordered={false}
              keyField="product_id"
            />
          </div>
          {rowData?.length > 0 && pageCount > 1 ? (
            <PrimaryPagination
              pageCount={pageCount}
              setPageNo={setPageNo}
              pageNo={pageNo}
            />
          ) : null}
        </CardBody>
      </Card>
    </div>
  );
};
export default ReferralProductsDetail;
