import React from "react";
import { useState, useEffect } from "react";
import TopHeading from "components/Common/TopHeading";
import DateFilter from "components/Common/DateFilter";

import { CardBody, Card, Row, Col } from "reactstrap";
import { useParams } from "react-router-dom";
import { userDashboard } from "services/SettingsServices";
import ProgressPopup from "components/Common/Popups/ProgressPopup";
import moment from "moment";
import { getEmployeeTask } from "services/TaskServices";
import PrimaryTable from "components/Common/PrimaryTable";
import PrimaryPagination from "components/Common/Pagination/PrimaryPagination";
import { setPageTitle } from "utils/pageTitle";
import EmployeeCardsSection from "components/Employees/EmployeeCardsSection";
import EmployeeDetailSection from "components/Employees/EmployeeDetailSection";
import { convertUtcDateToLocal } from "utils/date";

function EmployeeDetail() {
  const [loading, setLoading] = useState(false);
  const [employeeData, setEmployeeData] = useState({});
  const [taskData, setTaskData] = useState([]);
  const [pageNo, setPageNo] = useState(1);
  const [pageCount, setPageCount] = useState();
  const [dates, setDate] = useState(null);
  const { id } = useParams();

  useEffect(() => {
    setPageTitle("Employee Dashboard - Inspire Uplift");
  }, []);

  useEffect(() => {
    setLoading(true);
    userDashboard(id)
      .then(res => {
        setEmployeeData(res.data);
      })
      .catch(err => {
        console.log(err);
      })
      .finally(res => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    let startDate;
    let endDate;

    if (dates) {
      startDate = moment(dates.startDate).format("YYYY-MM-DD");
      endDate = moment(dates.endDate).format("YYYY-MM-DD");
    }

    getEmployeeTask(id, pageNo, startDate, endDate)
      .then(res => {
        setTaskData(res?.data?.data);
        setPageCount(res?.data?.last_page);
      })
      .catch(err => {
        console.log(err);
      });
  }, [pageNo, dates?.startDate, dates?.endDate]);

  const columns = [
    {
      dataField: "id",
      text: "#",
      formatter: (cell, row) => (
        <div className="font-semi-bold">{(pageNo - 1) * 15 + row.index}</div>
      ),
    },
    {
      dataField: "title",
      text: "Title",
      formatter: (cell, row) => (
        <div  className="product-title-container font-semi-bold" style={{ width: "300px" }}>{row.title}</div>
      ),
    },
    {
      dataField: "assigned_by",
      text: "Assigned By",
      formatter: (cell, row) => (
        <div>{row.assigned_by?.name}</div>
      ),
    },
    {
      dataField: "priority",
      text: "Priority",
      formatter: (cell, row) => (
        <div
          className={`status ${ 
            row.priority === "normal" | row.priority == "low"
              ? "success-status"
              : row.priority === "medium"
              ? "warning-status"
              : row.priority === "high"
              ? "danger-status"
              : ""
          } w-max-content`}
        >
          {row.priority}
        </div>
      ),
    },
    {
      dataField: "status",
      text: "Status",
      formatter: (cell, row) => (
        <div
          className={`status ${
            row.status === "completed"
              ? "success-status"
              : row.status === "pending"
              ? "warning-status"
              : ""
          }  w-max-content`}
        >
          {row.status}
        </div>
      ),
    },
    {
      dataField: "assigned_on",
      text: "Assigned On",
      formatter: (cell, row) => (
        <div>{convertUtcDateToLocal(moment(row?.assigned_on).format("DD-MM-YYYY hh:mm:ss a"))}</div>
      ),
    },
    {
      dataField: "task_notifications",
      text: "Update",
      formatter: (cell, row) =>
        row?.task_notifications > 0 ? (
          <div
            className={`d-flex justify-content-between align-items-center task-notifications-count`}
          >
            {row.task_notifications}
          </div>
        ) : null,
    },
  ];

  const data = taskData?.map((item, index) => {
    return {
      ...item,
      index: index + 1,
    };
  });

  return (
    <div className="page-content">
      {loading && <ProgressPopup label={"Loading..."} />}
      <div className="mb-3 d-flex justify-content-between align-items-center">
        <TopHeading heading={"Employee Dashboard"} />
        <DateFilter defaultActiveTab={null} setDate={setDate} dates={dates} />
      </div>
      <Row>
        <Col sm="4">
          <EmployeeDetailSection employeeData={employeeData} />
        </Col>
        <Col sm="8">
          <EmployeeCardsSection employeeData={employeeData} />
          <Card>
            <CardBody>
              <div className="mt-3">
                <PrimaryTable
                  columns={columns}
                  data={data}
                  responsive
                  bordered={false}
                  scrollable
                  keyField="id"
                  wrapperClasses="table-responsive"
                  rowUrl={"/employees/my-task"}
                  isPermission={true}
                />
              </div>
              {taskData?.length > 0 && (
                <PrimaryPagination
                  pageCount={pageCount}
                  pageNo={pageNo}
                  setPageNo={setPageNo}
                />
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
}

export default EmployeeDetail;
