import moment from "moment";
import "moment-timezone";

export const getCurrentTimeInEST = () => {
  const inputDate = new Date();
  const inputTimeZone = "Asia/Karachi";

  // Parse the input date with the given format and timezone
  const parsedDate = moment.tz(
    inputDate,
    "YYYY-MM-DD hh:mm:ss a",
    inputTimeZone
  );

  // Convert it to Eastern Standard Time (EST)
  const estTimeZone = "America/New_York"; // Eastern Standard Time
  const estDateTime = parsedDate
    .clone()
    .tz(estTimeZone)
    .format("YYYY-MM-DD hh:mm:ss A");
  return estDateTime;
};

export function getUserTimeZone() {
  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  return userTimeZone;
}

export function convertLocalToUTC(date) {
  return moment(date).utc().format("YYYY-MM-DDTHH:mm");
}

export function convertLocalDatesToUTC(
  startDate,
  endDate,
  outputFormat = "YYYY-MM-DDTHH:mm:ss"
) {
  // Set the start of the day for the start date in the user's time zone
  const utcStartDate = moment
    .tz(startDate, getUserTimeZone())
    .startOf("day")
    .utc()
    .format(outputFormat);

  // Set the end of the day for the end date in the user's time zone
  const utcEndDate = moment
    .tz(endDate, getUserTimeZone())
    .endOf("day")
    .utc()
    .format(outputFormat);

  return { utcStartDate, utcEndDate };
}

export function getCurrentUTCTime(outputFormat) {
  return moment.tz(getUserTimeZone()).utc().format(outputFormat);
}

export function convertUtcDateToLocal(
  date,
  outputFormat = "DD-MM-YYYY hh:mm:ss A"
) {
  const localTime = moment
    .utc(date, "DD-MM-YYYY hh:mm:ss A")
    .tz(getUserTimeZone())
    .format(outputFormat); // Format the local time
  return localTime;
}

function areDatesSameDay(startDate, endDate, userTimeZone) {
  const localStartDate = moment(startDate).tz(userTimeZone).startOf("day");
  const localEndDate = moment(endDate).tz(userTimeZone).startOf("day");
  return localStartDate.isSame(localEndDate, "day");
}

export function convertGraphDataToLocal(data, startDate, endDate, keyName) {
  let convertedData = data?.map(item => {
    const utcTime =
      keyName == "order_amount" ? moment.utc(item[0]) : moment.utc(item.time); // Parse the UTC time
    const localTime = utcTime.tz(getUserTimeZone()); // Convert to local time

    if (areDatesSameDay(startDate, endDate, getUserTimeZone())) {
      // If the date is today, return only the time (hour)
      if (keyName === "order_amount") {
        return [localTime.format("h:mm A"), item[1]];
      } else {
        return {
          ...item,
          time: localTime.format("h:mm A"), // Format the time for today (e.g., "9 AM")
        };
      }
    } else {
      // If the date is not today, return full local date and time
      if (keyName === "order_amount") {
        return [localTime.format("DD-MMM"), item[1]];
      } else {
        return {
          ...item,
          time: localTime.format("DD-MMM"), // Full date and time
        };
      }
    }
  });
  return convertedData;
}
